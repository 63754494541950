@import "app/styles/abstracts/shared-variables";

.paymentSummaryTitle {
    font-weight: 300;
    font-size: $h1-font-size;
}

.title {
    line-height: 2rem;
    color: var(--body-emphasis-color);
    font-weight: 500;
    text-transform: uppercase;
}

.cardContainer {
    margin-top: 0 !important;
    margin-left: -1rem;
    margin-right: -1rem;
}