@import '/app/styles/abstracts/shared-variables.scss';

.container {
  padding: 1rem;
  background-color: var(--data-tile-bg);;
  border-radius: var(--border-radius);
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }

  &.isDate {
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.wrapper {
  margin-bottom: 0.5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.name {
  font-size: $b3;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  margin-bottom: 0;
}

.content {
  font-size: $b3;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 0;
}
