@import '@/app/styles/abstracts/shared-variables.scss';

.progressHeadingContainer {
    padding-left: 1em;
    padding-right: 1em;
}

.progressHeading {
    font-size: $h4-font-size;
    font-weight: 500;
    line-height: 160%; /* 2rem */
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}