@import "../../../../../app/styles/abstracts/shared-variables";

.container {
  padding: 0.5rem 0;
  display: inline-flex;
  align-items: center;
  transition: color 0.15s ease-in-out;
  text-decoration: none !important;

  svg {
    color: var(--body-color);
  }

  &:hover {
    cursor: pointer;

    & > * {
      color: var(--by-light-action-color-interactive-hover);
    }
  }
}

.name {
  margin-left: 1rem;
  font-size: $b2;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: 0;
  color: var(--body-color);
}
