@import "app/styles/abstracts/shared-variables";

.financeYourProjectContainer {
  .card-header {
    width: 100%;
  }
  h4 {
    width:100%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    @include media-breakpoint-down(lg) {
      flex-direction: column;

      > div {
        width: 100%;
        padding-right: map-get($spacers, 2);

        button {
          margin: map-get($spacers, 2) 0;
          width:100%;
        }
      }
    }
  }
}

.subSectionContainer {
  border-top: 1px solid var(--mainsection-body-border-top) !important;
  margin-top: 0 !important;
}

.subSectionContainer :global .card-body {
  padding-top: 0;
}

.subSectionContainer :global .card-header {
  h4 {
    align-items: flex-start;
  }
}
