.nextUpContainer.isOnlyHeader {
  & > div:nth-child(1) {
    border-bottom: none;
    border-radius: var(--border-radius);
  }
  & > div:nth-child(2) {
    display: none;
  }
}

.nextUpContainer.isEmptySubsection {
  & > div:nth-child(2) {
    & > div:nth-child(2) {
      display: none;
    }
  }
}

.nextUpContainer.isHide {
  display: none;
}

// Next Up Action Definitions Custom Styles

// Measure Appointment Scheduled
.nextUp__measureAppointmentScheduledSubtitle {
  margin-top: 0.5rem;
  margin-bottom: 0;

  & .link {
    color: var(--link-color);
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      color: var(--link-hover-color);
    }
  }
}
