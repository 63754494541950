@import '@/app/styles/abstracts/shared-variables.scss';

.estimatesWidgetCard {
  :global .card-body {
    padding: 0 map-get($spacers, 3);
  }

  border-radius: var(--border-radius-sm);
}

.estimatesWidgetButtonCol {
  justify-content: center;
  align-items: center;

  & button {
    width: 50%;
  }

  @include media-breakpoint-up(md) {
    justify-content: flex-end;

    & button {
      width: initial;
    }
  }
}

.estimatesWidgetPriceCol {
  font-weight: bold;

  @include media-breakpoint-up(md) {
    order: 1;
  }

  @include media-breakpoint-up(lg) {
    order: 0;
    text-align: right;
  }
}

.estimatesWidgetRow {
  padding: map-get($spacers, 2) 0;
  align-items: center;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--bs-card-border-color);
  }

  &>div {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  @include media-breakpoint-up(lg) {
    &>div {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
