@import '@/app/styles/abstracts/shared-variables.scss';
@import '@/app/styles/abstracts/_mixins.scss';

.btn {
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.surfaceGreen {
    color: var(--btn-beta-color);
    background-color: var(--btn-beta-bg);
    border-color: var(--btn-beta-border-color);

    &:hover {
      color: var(--btn-beta-hover-color);
      background-color: var(--btn-beta-hover-bg);
      border-color: var(--btn-beta-hover-border-color);
    }

    &:active {
      color: var(--btn-beta-active-color);
      background-color: var(--btn-beta-active-bg);
      border-color: var(--btn-beta-active-border-color);
    }
  }

  &.success,
  &.success:active,
  &.primary,
  &.primary:hover {
    color: var(--color-contrast-light);
  }

  &.surfaceBlue,
  &.surfaceBlue:active {
    color: var(--white);
  }

  @include outlineL2(primary);
  @include outlineL2(success);
  @include outlineL2(danger);

  &.isImg span {
    color: transparent;
  }

  &.disabled,
  &.disabled:active {
    background-color: $by-light-text-color-disabled;
    border: 1px solid transparent;
    color: $by-light-text-color-disabled;
    cursor: not-allowed;
    pointer-events: all;
    opacity: 1;

    & .btnContentWrapper {
      pointer-events: none;
    }
  }
}

.img {
  object-fit: contain;

  &.sm {
    padding: $btn-padding-y-sm $btn-padding-x-sm;
  }
  &.md {
    padding: $btn-padding-y $btn-padding-x;
  }
  &.lg {
    padding: $btn-padding-y-lg $btn-padding-x-lg;
  }
}

.btnLoader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  & ~ span,
  & ~ .img {
    opacity: 0;
  }
}

.btnContentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
