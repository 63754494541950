.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  &.isFullScreen {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
  }
}
