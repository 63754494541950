@import '/app/styles/abstracts/shared-variables.scss';
@import './NextUpItem.variables.scss';

.container {
  display: flex;
  justify-content: space-between;
  margin: -1rem;
  padding: 1rem;
  transition: background-color 0.15s ease-in-out;
  flex-direction: column;

  &.isNextUpAction {
    flex-direction: row;

    &:hover {
      cursor: pointer;
      background-color: var(--by-light-action-color-neutral-hover);
    }

    .iconArrowWrapper {
      display: flex;
    }
  }

  &.isButtonElement {
    flex-direction: column;

    .contentWrapper {
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  button {
    @include media-breakpoint-up(md) {
      min-width: 200px;
      margin-left: 1rem;
    }
  }
}

.contentWrapper {
  display: flex;
}

.dotWrapper {
  &.dotRequired {
    color: $status-dot-red;
  }
}

.title {
  font-size: $b3;
  font-weight: 500;
  line-height: 150%;
  /* 1.5rem */
  margin-bottom: 0;
}

.subtitle {
  font-size: $b3;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.richText {
  font-size: $b3;
  font-weight: 400;
  line-height: 1.5rem;
  border-top: 1px solid var(--by-light-border-color-default);
  margin: 0.5rem 1rem 0.5rem 0;
  padding-top: 0.5rem;
  margin-bottom: 0;
}

.iconArrowWrapper {
  display: none;
  align-items: center;
  margin-right: -8px;
}
