@import '@/app/styles/abstracts/shared-variables.scss';
@import '@/app/styles/abstracts/_mixins.scss';

.mobileTabContainer {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  margin-bottom: 1rem;
  transition: background-color 0.15s ease-in-out;
  text-decoration: none !important;

  &:hover {
    cursor: pointer;
    background-color: var(--by-light-action-color-neutral-hover);
  }
  &.active {
    background-color: var(--by-light-action-color-interactive-subdued-pressed);
  }
}

.mobileTabContentWrapper {
  padding: 0.25rem 0;
}

.mobileTabName {
  font-size: $b3;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 0;
  color: var(--by-light-text-color-primary);
}

.mobileTabDescription {
  font-size: $b5;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--by-light-text-color-tertiary);
}

.mobileTabArrowWrapper {
  margin-left: 0.5rem;
}

.tabContainer {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  padding-bottom: 0.5rem;
  border-bottom: none;
  @include customScrollbar;
}

.tabNavItem {
  flex: 0 0 auto;
  position: relative;
  @include bottom-border(-1px, 1px, var(--border-color));

  &.active {
    position: relative;
    @include bottom-border(-1px, 2px, var(--nav-tabs-link-active-border-color));

    a {
      background-color: transparent;
      font-weight: 500;
    }
  }
}

.tabItem {
  display: inline-block !important;
  font-size: $b2;
  line-height: 2rem;
  font-weight: 400;
  border-color: transparent !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 2px solid transparent !important;
  padding: 0.5rem 1rem 7px;
  text-decoration: none !important;
  color: var(--body-color) !important;

  &:hover {
    cursor: pointer;
    color: var(--body-color) !important;
    position: relative;
    @include bottom-border(-3px, 1px, var(--nav-tabs-link-active-border-color));
  }
}
