.otpInput {

  span {
    display: inline-block;
    height:2px;
    background: #aaa;
    width: 15px;
    margin-left: 2px;
    margin-right: 2px;
  }

  span:nth-child(2), span:nth-child(4), span:nth-child(8), span:nth-child(10) {
    width: 0px;
    background: transparent;
  }

  span:nth-child(6) {
    margin-left: 10px;
    margin-right: 10px;
  }

}