.wrapper {
  display: flex;
  align-items: center;
}

.iconWrapper {
  margin-right: 0.5rem;
  color: var(--by-light-surface-color-red);
}

.title {
  padding: 0.25rem 0;
  line-height: 2rem;
  margin-bottom: 0;
}
