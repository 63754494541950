@import '@/app/styles/abstracts/shared-variables.scss';

.stepsContainer {
    display: flex;
    flex-direction: column;
}

.segment {
    display: flex;
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    min-height: 2rem;
    margin-left: .25rem;
    margin-right: .25rem;
    overflow: visible;

    svg {
        overflow: visible;
    }
}

.circleNotStarted {
    background-color: var(--step-bar-not-completed-bg);
    color: var(--step-bar-not-completed-color);
    border: 1px solid var(--step-bar-not-completed-border-color);
}

.circleComplete {
    background-color: var(--step-bar-completed-bg);
    color: var(--step-bar-completed-color);
}

.circleCompletePartial {
    color: var(--step-bar-current-color);
    background-color: var(--step-bar-current-bg);
    border: 1px solid var(--step-bar-current-border-color);
}

.tasks {
    margin-left: 1rem;
    margin-right: 1rem;
}

.segmentTitle {
    font-size: $b3;
    font-weight: 500;
    line-height: 150%; /* 1.5rem */
    margin-bottom: 0;
}

.segmentSubTasks {
    font-size: $b5;
    font-weight: 400;
    line-height: 133.33%; /* 1rem */
}

.verticalSeparator {
    height: 2rem;
    margin: .5rem 0 .5rem .25rem;
}

.verticalLineComplete {
    border-left: 0.0625rem solid var(--step-bar-completed-bg);
}

.verticalLineIncomplete {
    border-left: 0.0625rem dashed var(--step-bar-not-completed-border-color);
}