@mixin outlineL2($color) {
  &.outlineL2 {
    @if $color == primary {
      &.#{$color} {
        background-color: var(--btn-gamma-bg);
        color: var(--btn-gamma-color);
        border-color: var(--btn-gamma-border-color);

        &:active {
          color: var(--btn-gamma-active-color);
          background-color: var(--btn-gamma-active-bg);
          border-color: var(--btn-gamma-active-border-color);
        }

        &:hover {
          background-color: var(--btn-gamma-hover-bg);
          border-color: var(--btn-gamma-hover-border-color);
          color: var(--btn-gamma-hover-color);
        }
      }
    }

    @if $color == success {
      &.#{$color} {
        background-color: transparent;
        color: var(--by-light-action-color-green);
        &:hover {
          background-color: var(--by-light-action-color-green-subdued-hover);
          border-color: var(--by-light-action-color-green-hover);
          color: var(--by-light-action-color-green-hover);
        }
      }
    }

    @if $color == danger {
      &.#{$color} {
        background-color: transparent;
        color: var(--by-light-action-color-red);
        &:hover {
          background-color: var(--by-light-action-color-red-subdued-hover);
          border-color: var(--by-light-action-color-red-hover);
          color: var(--by-light-action-color-red-hover);
        }
      }
    }
  }
}

@mixin customScrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #fafafa;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
}

@mixin bottom-border(
  $bottom: -1px,
  $height: 1px,
  $background-color: $border-color,
  $padding-x: 0,
  $visibility: visible
) {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: $bottom;
    width: 100%;
    height: $height;
    background-color: $background-color;
    visibility: $visibility;
    padding: 0 $padding-x;
  }
}

@mixin top-border(
  $top: -1px,
  $height: 1px,
  $background-color: $border-color,
  $padding-x: 0,
  $visibility: visible
) {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: $top;
    width: 100%;
    height: $height;
    background-color: $background-color;
    visibility: $visibility;
    padding: 0 $padding-x;
  }
}

@mixin sectionHeaderWrapper($borderRadius: var(--mainsection-border-radius), $titleColor: $black) {
  .headerWrapper {
    h4 {
      color: $titleColor;
    }

    &.isCollapsible {
      &:hover {
        cursor: pointer;
      }
    }

    &.isCollapsed {
      border-bottom: none;

      @include media-breakpoint-up(md) {
        border-radius: $borderRadius;
      }
    }

    &.isBodyEmpty {
      border-bottom: none;
      border-radius: $borderRadius;
    }
  }
}

@mixin applyAlertStyles($color) {
  border: 1px solid var(--alert-#{$color}-border-color, transparent);
  background-color: var(--alert-#{$color}-bg);

  .stripeWrapper {
    visibility: var(--alert-stripe-visibility);

    .stripe {
      background-color: var(--alert-#{$color}-indicator);
    }
  }

  .iconWrapper {
    color: var(--alert-#{$color}-indicator);
  }
}
