@import '@/app/styles/abstracts/shared-variables.scss';

.container {
  height: 3.5rem;
  background: none;
}

.containerMobile {
  background: none;
  gap: 1rem;
  height: 0.5rem;
}

.segment {
  font-size: $b3;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%; /* 1.25rem */
  padding-left: 2rem;
  padding-right: 2rem;
  white-space: normal;
}

.segmentMobile {
  white-space: normal;
  height: 0.5rem;
}

.firstSegment {
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
}

.lastSegment {
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
}

.segmentNotStarted {
  background-color: var(--step-bar-not-completed-bg);
}

.segmentComplete {
  background-color: var(--step-bar-completed-bg);
  color: var(--step-bar-completed-color);
}

.segmentCompletePartial {
  background-color: var(--step-bar-current-bg);
  color: var(--step-bar-current-color);
}

.segmentCompletePartialMobile {
  border: 1px solid var(--step-bar-current-border-color);
}
