@import '@/app/styles/abstracts/shared-variables.scss';

.taskHeadingContainer {
  padding-left: 1em;
  padding-right: 1em;
}

.taskHeading {
  font-size: $b2;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.trackProjectBody {
  & :global(.card-body) {
    border-top: 0;
    border-bottom: 1px solid var(--mainsection-body-border-top);

    @include media-breakpoint-up(lg) {
      border-bottom: 0;
      border-top: 1px solid var(--mainsection-body-border-top) !important;
    }
  }
}
