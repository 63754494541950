@import '@/app/styles/abstracts/shared-variables.scss';
@import '@/app/styles/abstracts/_mixins.scss';

.title {
  line-height: 160%;
  font-weight: 500;
  margin-bottom: 0;
}

.subtitle {
  font-size: $b3;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 0;
}

.modalHeader {
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1rem 0;
  border-bottom: 0;
  @include bottom-border($padding-x: var(--modal-divider-padding-x), $background-color: var(--modal-divider-color), $visibility: var(--modal-divider-visibility));

  // override close btn
  button {
    padding: 12px !important;
    margin-right: -12px !important;
  }
}

.modalBody {
  @include customScrollbar;
}

.modalFooter {
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1rem 0;
  border: 0;

  @include top-border($padding-x: var(--modal-divider-padding-x), $background-color: var(--modal-divider-color), $visibility: var(--modal-divider-visibility));
}

.noTopBorder {
  &::before { content: none; }
}

.noBottomBorder {
  &::before { content: none; }
}

.noPadding {
  padding: 0;
}