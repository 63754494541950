.addToCalendarBtnWrapper {
  display: inline-flex;
  align-items: center;
  text-decoration: none !important;
  margin-top: 0.25rem;
  border: none;
  padding: 0;
  background-color: transparent;

  svg,
  span {
    color: var(--link-color);
  }
}
