@import '@/app/styles/abstracts/_mixins.scss';

.customAlert {
    border-radius: var(--alert-border-radius)!important;
    border-width: var(--alert-border-width);
    box-shadow: var(--alert-box-shadow)!important;

    &.warning {
        @include applyAlertStyles(warning);
    }
    
    &.success {
        @include applyAlertStyles(success);
    }
    
    &.danger{
        @include applyAlertStyles(danger);
    }
}